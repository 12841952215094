@tailwind base;
@tailwind components;
@tailwind utilities;

/* RULES */
/* ---------------------------------------------------- */
@font-face {
  font-family: serif, sans-serif;
  src: url(./fontawesome-webfont.woff2) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: serif, sans-serif;
  src: url(./free-fa-brands-400.woff2) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@keyframes para {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%,
      400px 0;
  }
}

/* ELEMENT STYLES */
/* ---------------------------------------------------- */
body {
  overflow-x: hidden;
}

#connectBG {
  height: 100%;
  width: 1000px;
  background-color: hsla(200, 40%, 30%, 0.4);
  background-image: url("https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png"),
    url("https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png"),
    url("https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png"),
    url("https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png");
  background-repeat: repeat-x;
  background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
  background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
  animation: 50s para infinite linear;
}

/* MODAL STYES */
/* ---------------------------------------------------- */
.modalContainer {
  background: #fafafa;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3),
    0 0 300px 25px rgba(222, 198, 122, 0.7) inset; */
  margin: auto;
  border: rgba(0, 0, 0, 1);
  border-radius: 0.5rem;
  border-width: 2px 2px 2px 2px;
}

.modalContainer:before,
.modalContainer:after {
  content: "";
  background: #fafafa;
  border: rgba(0, 0, 0, 1);
  border-radius: 0.5rem;
  border-width: 2px 2px 2px 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2),
    inset 0 0 300px rgba(222, 198, 122, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -2;
  transition: 0.1s;
}

.modalContainer:before {
  transform: rotate(-1.5deg);
}

.modalContainer:after {
  transform: rotate(2.4deg);
}

.modalContainer:hover:before {
  transform: rotate(0deg);
  border: rgba(0, 0, 0, 1);
  border-radius: 0.5rem;
  border-width: 2px 2px 2px 2px;
}

.modalContainer:hover:after {
  transform: rotate(0deg);
  border: solid rgba(0, 0, 0, 1);
  border-radius: 0.5rem;
  border-width: 2px 2px 2px 2px;
}

#modalCloseButton {
  border: solid rgba(0, 0, 0, 1);
  border-width: 2px 2px 2px 2px;
  border-radius: 0.5rem;
}

.modalContainer:hover #modalCloseButton {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.modalButton {
  border: solid rgba(0, 0, 0, 1);
  border-width: 2px 2px 2px 2px;
  border-radius: 0.5rem;
}

.modalContainer:hover .lyricButton {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0.2rem;
}

/* NAVIGATION STYLES */
/* ---------------------------------------------------- */
.nav {
  width: 100%;
  height: 50px;
  background-color: #262626;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  place-items: center start;
}

.nav ul {
  position: absolute;
  top: 0;
  left: 0;
  float: right;
  padding: 0 25px;
  z-index: 15;
}

.nav ul button {
  list-style-type: none;
  padding: 5px 10px;
}

.hamburger {
  width: 2rem;
  height: 2rem;
  justify-content: space-around;
  justify-items: center;
  flex-flow: column nowrap;
  margin: 0.25rem 0 0.25rem 0.5rem;
  z-index: 10;
}

.burger {
  width: 2rem;
  height: 0.33rem;
  margin: 5px 5px 5px 5px;
  border-radius: 10px;
  background-color: white;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.patty2open {
  transform: translateX(100%);
  opacity: 0;
}

.patty2closed {
  transform: translateX(0);
  opacity: 1;
}
